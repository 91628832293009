<template>
	<div>
		<CRow>
			<CCol md="12">
				<CCard>
					<CCardHeader>
						<CRow>
							<CCol md="8">
								<h4>Edit Role</h4>
							</CCol>
							
						</CRow>
					</CCardHeader>
					<CForm v-on:submit.prevent="storeProcess" class="fwdwd">
						<CCardBody>
							<h4>Proses Status Permohonan</h4>
							<table class="table table-bordered table-sm table-striped">
								<thead>
									<tr>
										<th style="width: 15px;">No</th>
										<th>Kategori Permohonan - Status Permohonan</th>
										<th><div class="btn btn-sm btn-success" v-on:click="addRowStatus"><i class="fa fa-plus"></i></div></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(value_list_akses_proses, key_list_akses_proses) in list_akses_proses" :key="key_list_akses_proses">
										<td>{{key_list_akses_proses+1}}</td>
										<td>
											<v-select :filter="searchStatus" :options="list_status_permohonan" label="st_nama" v-model="value_list_akses_proses.status_permohonan">
												<template v-slot:option="value_list_status_permohonan">
													{{ (value_list_status_permohonan.is_pemasukan) ? 'Pemasukan' : ((value_list_status_permohonan.is_pemasukan == false)?'Pengeluaran':'') }} {{ value_list_status_permohonan.st_kategori }} - {{ value_list_status_permohonan.st_nama }}
												</template>
												<template #selected-option="value_list_status_permohonan">
													{{ (value_list_status_permohonan.is_pemasukan) ? 'Pemasukan' : ((value_list_status_permohonan.is_pemasukan == false)?'Pengeluaran':'') }} {{ value_list_status_permohonan.st_kategori }} - {{ value_list_status_permohonan.st_nama }}
												</template>

											</v-select>
										</td>
										<td><div class="btn btn-sm btn-danger"><i class="fa fa-times"></i></div></td>
									</tr>
								</tbody>
							</table>
							<div class="alert alert-info"><i class="fa fa-info"></i> Menambah atau mengurangi proses status role ini, berakibat pada alur proses pada persetujuan permohonan.</div>
						</CCardBody>
						<CCardFooter>
							<div class="text-right">
								<button type="submit" class="btn btn-primary text-white btn-hover-outline-primary"><i class="fa fa-save"></i> Simpan</button>
							</div>
						</CCardFooter>
					</CForm>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>
<script>
	export default {
		name: "EditRole",
		data() {
			return {
				name: '',
				ditjen: '',
				slug: '',
				idRole: this.$route.params.idRole,
				list_akses_proses: [],
				list_status_permohonan:[]
			};
		},
		methods: {
			addRowStatus(){
				this.list_akses_proses.push({
					status_permohonan: {},
				});
			},
			searchStatus(options, search) {
              const fuse = new Fuse(options, {
                keys: ["st_kategori", "st_nama"],
                shouldSort: true
              });
              return search.length
                ? fuse.search(search).map(({ item }) => item)
                : fuse.list;
            },
			storeProcess: function () {
				Swal.fire({
					title: 'Do you want to save the changes?',
					showDenyButton: true,
					showCancelButton: true,
					confirmButtonText: 'Save',
					denyButtonText: `Don't save`,
					}).then((result) => {
					/* Read more about isConfirmed, isDenied below */
					if (result.isConfirmed) {
						let self = this;
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
					allowOutsideClick: false,
				});
				axios.put(
                    this.apiLink + "api/crud/role/edit_status_permohonan",
                    {
                        id: this.idRole,
                        list_akses_proses: JSON.stringify(this.list_akses_proses),
                        
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + this.access_token,
                        },
                    }
                )
				.then(function (response) {
					var res_data = response.data;
					// console.log(res_data);
					// return false;
					if (res_data.meta.code != "200") {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: res_data.data.message
						});
					}
					else{
						let timerInterval;
						Swal.fire({
							icon: "success",
							title: "Berhasil",
							text: "Anda akan diarahkan ke halaman master role segera",
							timer: 2000,
							timerProgressBar: true,
							showCancelButton: false,
							showConfirmButton: false,
							didOpen: () => {
								timerInterval = setInterval(() => {
									const content = Swal.getContent();
									if (content) {
										const b = content.querySelector("b");
										if (b) {
											b.textContent = Swal.getTimerLeft();
										}
									}
								}, 100);
							},
							willClose: () => {
								clearInterval(timerInterval);
							},
						}).then((result) => {
							/* Read more about handling dismissals below */
							if (result.dismiss === Swal.DismissReason.timer) {
								self.$router.push({name: 'AllRole'});
							}
						});
					}
				});
					} else {
						Swal.close();
					}
				});
				
			},
		},
		created() {
            Swal.fire({
                title: '<i class="fa fa-refresh fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
                allowOutsideClick: false,
            });
           let self = this;
            axios.get(this.apiLink + "api/crud/role", {
                params: {
                    id: this.idRole,
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_role = response.data;
                var res_role_data = res_role.data[0].data[0];
                if (res_role.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_role.data.message
                    });
                }
                else{
						$.each(res_role_data.status_permohonan, function(index, val) {
							self.list_akses_proses.push({
								status_permohonan: val,
							});
						});
					Swal.close();
                    
                    
                    
                }
            });
			axios.get(this.apiLink + "api/master/status_permohonan", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
			.then((response) => {
				var res_status_permohonan = response.data;
				var res_status_permohonan_data = res_status_permohonan.data;
				if (res_status_permohonan.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_status_permohonan.data.message
					});
				}
				else{
					this.list_status_permohonan = res_status_permohonan_data.master;
				}
			});
        }
	};
</script>